<template>
  <div>
    <div class="wrap">
      <div class="title">
        FAQ
      </div>
      <div class="flex-col flex my-[40px]">
        <div class="contents">
          <Accordion v-for="item in accordion" :no="item.faq_no" :title="item.faq_title" :image-src="item.faq_image" :content="item.faq_content" :category="'일반'" v-bind:key="item.faq_title"></Accordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";
export default {
  components: {Accordion},
  created() {

  },
  data () {
    return  {
      accordion: [

      ],
    }
  },
  mounted() {
    this.$store.dispatch('faq/getFaqList').then((data) => {
      this.accordion = data
    })
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/faq.scss";
</style>

<style lang="scss">
  @import "@/assets/scss/variables";
  .content-wrap {
    a {
      color: $primary !important;
      font-weight: bold;
    }
    a:hover {
      text-decoration: underline;
    }
  }

</style>
